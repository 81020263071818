import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QualityDashboardComponent } from "./quality-dashboard/quality-dashboard.component";

import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "../../guards/auth-guard.service";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SecureGuard } from "../../guards/secure.guard";

const routes: Routes = [
	{
		path: "quality",
		children: [
			{
				path: "",
				pathMatch: "full",
				canActivate: [AuthGuardService, SecureGuard],
				component: QualityDashboardComponent,
			},
		],
	},
];

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		MatBadgeModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatMenuModule,
		MatPaginatorModule,
		MatRadioModule,
		MatSortModule,
		MatTableModule,
		ReactiveFormsModule,
		FormsModule,
		RouterLink,
		MatTooltipModule,
		QualityDashboardComponent,
	],
})
export class QualityModule {}
